<template>
<ClientOnly>
  <Popover class="relative bg-orange">
    <div
      class="flex items-center justify-between px-4 py-3 sm:px-6 md:justify-start md:space-x-10 border border-b-1 bg-orange-400"
    >
      <div class="flex justify-start lg:w-0 lg:flex-1">
        <!-- LOGO -->
        <NuxtLink to="/">
          <div class="bg-white p-2 rounded-md">
            <img class="h-6 w-auto sm:h-6" :src="logoUrl" alt="" />
          </div>
        </NuxtLink>
      </div>
      <div class="-my-2 -mr-2 md:hidden">
        <!-- バーガーボタン -->
        <PopoverButton
          class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span class="sr-only">Open menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </PopoverButton>
      </div>
      <div class="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
        <NuxtLink to="/informations" class="ml-8 text-base font-medium text-white hover:text-gray-900">お知らせ</NuxtLink>
        <NuxtLink v-if="!isLoggedIn()" to="/signup" class="ml-8 text-base font-medium text-white hover:text-gray-900">会員登録</NuxtLink>
        <NuxtLink v-if="isLoggedIn()" to="/member" class="ml-8 text-base font-medium text-white hover:text-gray-900">会員ページ</NuxtLink>
        <button v-if="notificationStore.getNotifications().length > 0" @click="showNotifyModal" class="ml-8 text-base font-medium text-white hover:text-gray-900">
          <BellAlertIcon class="inline-flex h-6 w-6 text-white"></BellAlertIcon>
          <span v-if="notificationStore.getUncheckedCount() > 0" class="inline-flex items-center rounded-full bg-red-500 px-2 py-1 text-xs font-medium text-white">{{ notificationStore.getUncheckedCount() }}</span>
        </button>
        <div v-if="!isLoggedIn()" class="ml-8 dropdown bg-orange font-medium text-white">
          <label tabindex="0" class="m-1 bg-orange font-medium text-white">ログイン</label>
          <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 text-gray-400">
            <li><NuxtLink to="/signin">ショップサイト</NuxtLink></li>
            <li><a href="https://creator.sewmee.jp/signin">クリエイターサイト</a></li>
          </ul>
        </div>
        <a v-if="isLoggedIn()" @click="logout" class="ml-8 whitespace-nowrap text-base font-medium text-white hover:text-gray-900">ログアウト</a>
        <NuxtLink to="/creators/signup" class="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-lg border border-wite bg-transparent px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500"
        >クリエイター募集・登録</NuxtLink>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel focus class="z-40 absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
        <div class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="px-5 pt-5 pb-6">
            <div class="flex items-center justify-between">
              <div>
                <h1 class="text-2xl font-medium text-gray-900">SewMee</h1>
                <!-- <img class="h-8 w-auto" :src="logoUrl" alt="Your Company" /> -->
              </div>
              <div class="-mr-2">
                <PopoverButton ref="poButtonRef" class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
          </div>
          <div class="py-6 px-5">
            <div class="grid grid-cols-1 gap-4">
              <NuxtLink to="/informations" class="text-base font-medium text-gray-500 hover:text-gray-700">お知らせ</NuxtLink>
              <NuxtLink v-if="isLoggedIn()" to="/member" class="text-base font-medium text-gray-500 hover:text-gray-700">会員ページ</NuxtLink>
              <a v-if="isLoggedIn()" @click="logout" class="text-base font-medium text-gray-500 hover:text-gray-700">ログアウト</a>
              <button v-if="notificationStore.getNotifications().length > 0" @click="showNotifyModal" class="text-base font-medium text-gray-500 hover:text-gray-700">
                <BellAlertIcon class="inline-flex h-6 w-6 text-gray-500"></BellAlertIcon>
                <span v-if="notificationStore.getUncheckedCount() > 0" class="inline-flex items-center rounded-full bg-red-500 px-2 py-1 text-xs font-medium text-white">{{ notificationStore.getUncheckedCount() }}</span>
              </button>
            </div>
            <div class="mt-6">
              <NuxtLink v-if="!isLoggedIn()" to="signup" class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
              >会員登録</NuxtLink>
              <p v-if="!isLoggedIn()" class="mt-6 text-center text-base font-medium text-gray-500">
                会員登録がお済みの方は
                <a class="text-indigo-600 hover:text-indigo-500" @click="transferToSignIn">ログイン</a>
              </p>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <ModalContainer ref="notifyModalRef"
    size="small"
    :hide-cancel-button="true"
    submit-button-label="閉じる"
    :submit-handler="hideNotifyModal"
  >
    <template #header>
      <h1>通知一覧（過去30日）</h1>
    </template>
    <template #body>
      <div class="overflow-x-auto">
        <table class="table">
          <!-- head -->
          <tbody>
            <tr v-for="n in notificationStore.getNotifications()" class="hover:bg-base-200" @click="notifyCheck(n)">
              <td :class="n.checked ? 'font-normal' : 'font-bold'">{{ dayjs(n.created_at).format('YYYY-MM-DD') }}　{{ n.message }}</td>
              <td class="flex justify-end"><button class="btn btn-outline btn-success btn-sm" :disabled="n.checked">確認</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </ModalContainer>
</ClientOnly>
</template>

<script setup>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  BellAlertIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { useRouter } from "vue-router";
import logoUrl from "../assets/sewmee-color-logo.png";
import dayjs from 'dayjs'

const userStore = useUserStore()
const periodicJobStore = usePeriodicJobStore()
const notificationStore = useNotificationStore()
const favoriteStore = useFavoriteStore()

const { isLoggedIn } = userStore

if (process.client) {
  periodicJobStore.startJobs([
    {start: favoriteStore.reloadFavorites },
    {start: notificationStore.reloadNotifications },
  ], 30000)
}

const logout = () => {
  if (process.client) {
    userStore.logoutHandler()
    periodicJobStore.stopJobs()
    document.location.href = '/signout'
  }
}

const poButtonRef = ref()

const transferToSignIn = () => {
  navigateTo('/signin')
}

const notifyModalRef = ref()
const showNotifyModal = () => {
  notifyModalRef.value.showModal()
}
const hideNotifyModal = () => {
  notifyModalRef.value.hideModal()
}

const notifyCheck = (n) => {
  if (!n.checked) {
    notificationStore.applyChecked(n.target, n.id)
    n.chacked = true
  }
  if (['product_orders', 'cloth_orders'].includes(n.target)) {
    navigateTo(`/member/${n.target}`)
  } else {
    navigateTo(`/${n.target}/${n.target_id}`)
  }
  notifyModalRef.value.hideModal()
}
</script>